import { concatLatestFrom, createEffect } from '@ngrx/effects';
import { inject } from '@angular/core';
import { AuthStore } from 'app/core/auth/auth.store';
import { ChatService } from 'app/chat/chat.service';
import { EMPTY, switchMap } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { chatCounterActions } from 'app/store/shared.state';
import { SocketNotificationsService } from 'app/core/socket-notifications/socket-notifications.service';
import { Store } from '@ngrx/store';
import { selectUrl } from 'app/store/router.selectors';
import { filterNil } from 'app/shared/util/general-util';

export const initChatUnseen = createEffect(
    (authStore = inject(AuthStore), chatService = inject(ChatService), store = inject(Store)) =>
        authStore.token$.pipe(
            filter(() => authStore.allowChatFeature()),
            filterNil(),
            map(token => token.tenant_id),
            distinctUntilChanged(),
            tap(() => store.dispatch(chatCounterActions.resetCounter())),
            switchMap(() =>
                chatService.getTotalUnseen().pipe(
                    map(res => chatCounterActions.initCounterSuccess({ unseen: res })),
                    catchError(() => EMPTY)
                )
            )
        ),
    { functional: true }
);

export const increaseChatUnseen = createEffect(
    (
        socketNotificationService = inject(SocketNotificationsService),
        authStore = inject(AuthStore),
        store = inject(Store)
    ) =>
        socketNotificationService.chatMessage$.pipe(
            filter(() => authStore.allowChatFeature()),
            concatLatestFrom(() => store.select(selectUrl)),
            filter(
                ([data, url]) =>
                    url !== '/chat' &&
                    data.message.user !== authStore.getUserId() &&
                    !data.message.action &&
                    !data.message.edited
            ),
            map(() => chatCounterActions.increaseCounter())
        ),
    { functional: true }
);
