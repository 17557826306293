import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthStore } from './core/auth/auth.store';
import { SocketNotificationsService } from 'app/core/socket-notifications/socket-notifications.service';
import { DOCUMENT, CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Settings } from 'luxon';
import { JhiAlertComponent } from 'app/shared/alert/alert.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppComponentStoreService } from 'app/app-component-store.service';
import { LanguageService } from 'app/language-service';

@Component({
    standalone: true,
    selector: 'jhi-root',
    imports: [RouterOutlet, JhiAlertComponent, CommonModule],
    template: `
        <div id="app-content" [class.hidden]="showLandscapePrompt">
            <router-outlet></router-outlet>
            <jhi-alert></jhi-alert>
        </div>
        <div *ngIf="showLandscapePrompt" style="display: flex; justify-content: center;">
            <div style="z-index: 1001">
                <img src="/content/images/svg/logo-co.svg" class="logo-big" alt="logo" />
            </div>
            <div class="landscape-prompt">
                <p>Please rotate your device to landscape mode for the best experience.</p>
            </div>
        </div>
    `,
    styles: [
        `
            .hidden {
                display: none;
            }

            .landscape-prompt {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(255, 255, 255, 0.8);
                color: #000000;
                font-size: 36px;
                text-align: center;
                z-index: 1000;
            }

            .logo-container {
                margin-bottom: 20px;
            }

            .logo-big {
                width: 200px; /* Adjust the size as needed */
                height: auto;
                padding-top: 50px;
            }
            .rtl {
                direction: rtl;
            }
        `
    ]
})
export class AppComponent implements OnInit {
    private authStore = inject(AuthStore);
    private renderer = inject(Renderer2);
    private document = inject(DOCUMENT);
    private translateService = inject(TranslateService);
    private primeNGConfig = inject(PrimeNGConfig);
    public languageService = inject(LanguageService);
    rtl = '';
    showLandscapePrompt = false;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private appStore: AppComponentStoreService
    ) {
        this.authStore.setInitialState();
        this.authStore.checkDeviceId(inject(SocketNotificationsService).deviceId$);
        this.authStore.getAccount(this.authStore.select(s => s.token?.tenant_id));
        this.updateTranslationAndAttributeLang();
        this.updatePrimeNGTranslations();
    }

    ngOnInit() {
        this.languageService.isHebrew().subscribe(isHebrew => {
            if (isHebrew) {
                this.document.body.classList.add('rtl');
            } else {
                this.document.body.classList.remove('rtl');
            }
        });
        this.breakpointObserver
            .observe([
                '(orientation: portrait)',
                '(orientation: landscape)',
                '(max-width: 1280px)',
                '(min-width: 768px)'
            ])
            .subscribe((state: BreakpointState) => {
                this.appStore.setIsOnTablet(state.breakpoints['(max-width: 1280px)']);
                if (state.breakpoints['(orientation: portrait)']) {
                    this.showLandscapePrompt = true;
                } else if (state.breakpoints['(orientation: landscape)']) {
                    this.showLandscapePrompt = false;
                }
            });
        this.languageService.isHebrew().subscribe(isHebrew => {
            if (isHebrew) {
                this.rtl = 'rtl';
            } else {
                this.rtl = 'ltl';
            }
        });
    }

    updateTranslationAndAttributeLang() {
        this.authStore.langKey$.subscribe(lang => {
            Settings.defaultLocale = lang;
            this.renderer.setAttribute(this.document.querySelector('html'), 'lang', lang);
            this.translateService.use(lang);
            const isRtl = lang === 'he'; // Hebrew language uses RTL
            this.languageService.setIsHebrew(isRtl);
        });
    }

    updatePrimeNGTranslations() {
        this.translateService.stream('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
    }
}
