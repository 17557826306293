import { Injectable } from '@angular/core';
import { from, Observable, Subject, switchMap } from 'rxjs';
import {
    ConferenceCallMessage,
    SdsoMessage,
    SocketNotification,
    UpdatedFlagMessage,
    UpdatedProjectMessage,
    UpdatedStatusMessage
} from './socket-notifications.model';
import { IMessage, RxStomp, RxStompState, StompHeaders } from '@stomp/rx-stomp';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { AuthStore } from '../auth/auth.store';
import { TenantTokenVM } from '../auth/auth.model';
import { AlertService } from 'app/shared/alert/alert.service';
import { ChatMessageData, ToolTipData } from 'app/layouts/notifications/notification-item.model';

@Injectable({ providedIn: 'root' })
export class SocketNotificationsService {
    private disconnect$ = new Subject<void>();

    private rxStomp = new RxStomp();
    private updatedProject = new Subject<UpdatedProjectMessage>();
    private updatedStatus = new Subject<UpdatedStatusMessage>();
    private updatedJob = new Subject<any>();
    private conferenceCall = new Subject<ConferenceCallMessage>();
    private updatedFlag = new Subject<UpdatedFlagMessage>();
    private tenantChannel = new Subject<SocketNotification>();
    private deviceId = new Subject<string>();
    private chatMessage = new Subject<ChatMessageData>();

    isSocketConnected$ = this.rxStomp.connectionState$.pipe(
        map(state => state === RxStompState.OPEN),
        distinctUntilChanged()
    );
    updatedProject$ = this.updatedProject.asObservable();
    updatedStatus$ = this.updatedStatus.asObservable();
    updatedJob$ = this.updatedJob.asObservable();
    conferenceCall$ = this.conferenceCall.asObservable();
    updatedFlag$ = this.updatedFlag.asObservable();
    tenantChannel$ = this.tenantChannel.asObservable();
    deviceId$ = this.deviceId.asObservable();
    chatMessage$ = this.chatMessage.asObservable();

    constructor(
        private authStore: AuthStore,
        private alertService: AlertService
    ) {
        this.reconnectOnNewToken();
        this.disconnectOnLogout();
    }

    private reconnectOnNewToken() {
        this.authStore.token$
            .pipe(
                switchMap(token =>
                    from(this.rxStomp.deactivate()).pipe(
                        tap(() => {
                            this.disconnect$.next();
                            this.connectWebSocket(token);
                        })
                    )
                )
            )
            .subscribe();
    }

    private disconnectOnLogout() {
        this.authStore.isLoggedIn$
            .pipe(
                filter(isAuthenticated => !isAuthenticated),
                switchMap(() => this.rxStomp.deactivate())
            )
            .subscribe(() => this.disconnect$.next());
    }

    private connectWebSocket(token: TenantTokenVM) {
        const authHeader: StompHeaders = { Authorization: `Bearer ${token.access_token}` };

        this.rxStomp.configure({
            brokerURL: `${token.base_uri.replace('https', 'wss')}/notification-service/api/socket`,
            connectHeaders: authHeader,
            reconnectDelay: 0,
            connectionTimeout: 3_000
        });

        this.rxStomp.activate();

        const accessToken = this.authStore.decodeAccessToken(token.access_token);

        this.subscribeToUpdatedProject(accessToken.tenant, authHeader);
        this.subscribeToJob(accessToken.tenant, accessToken.user, authHeader);
        this.subscribeToConferenceCall(accessToken.tenant, accessToken.user, authHeader);
        this.subscribeToStatusChange(accessToken.tenant, authHeader);
        this.subscribeToFlagChange(accessToken.tenant, authHeader);
        this.subscribeToTenantChannel(accessToken.tenant, accessToken.user, authHeader);
        this.subscribeToUserChannel(accessToken.user, authHeader);
    }

    private subscribeToUpdatedProject(schemaName: string, authHeader: StompHeaders) {
        this.rxStomp
            .watch(`/notifications/${schemaName}`, authHeader)
            .pipe(this.socketPipe())
            .subscribe(notification => this.updatedProject.next(notification.data));
    }

    private subscribeToJob(schemaName: string, userId: number, authHeader: StompHeaders) {
        this.rxStomp
            .watch(`/notifications/task_done/${schemaName}/${userId}`, authHeader)
            .pipe(this.socketPipe())
            .subscribe(notification => this.updatedJob.next(notification.data));
    }

    private subscribeToConferenceCall(schemaName: string, userId: number, authHeader: StompHeaders) {
        this.rxStomp
            .watch(`/notifications/conference_calls/${schemaName}/${userId}`, authHeader)
            .pipe(this.socketPipe())
            .subscribe(notification => this.conferenceCall.next(notification.data));
    }

    private subscribeToStatusChange(schemaName: string, authHeader: StompHeaders) {
        this.rxStomp
            .watch(`/notifications/change_status/${schemaName}`, authHeader)
            .pipe(this.socketPipe())
            .subscribe(notification => this.updatedStatus.next(notification.data));
    }

    private subscribeToFlagChange(schemaName: string, authHeader: StompHeaders) {
        this.rxStomp
            .watch(`/notifications/flag/${schemaName}`, authHeader)
            .pipe(this.socketPipe())
            .subscribe(notification => this.updatedFlag.next(notification));
    }

    private subscribeToTenantChannel(schemaName: string, userId: number, authHeader: StompHeaders) {
        this.rxStomp
            .watch(`/notifications/${schemaName}/users/${userId}`, authHeader)
            .pipe(this.socketPipe())
            .subscribe((notification: SocketNotification) => {
                if (notification.data.notification.name === 'TOOLTIP') {
                    this.printAlert(notification.data.data as ToolTipData);
                } else if (notification.data.notification.name === 'CHAT') {
                    this.chatMessage.next(notification.data.data as ChatMessageData);
                } else {
                    this.tenantChannel.next(notification);
                }
            });
    }

    private subscribeToUserChannel(userId: number, authHeader: StompHeaders) {
        this.rxStomp
            .watch(`/notifications/users/${userId}`, authHeader)
            .pipe(this.socketPipe())
            .subscribe((notification: SdsoMessage) => this.deviceId.next(notification.data.data.deviceId));
    }

    private socketPipe() {
        return (source: Observable<IMessage>) =>
            source.pipe(
                map(message => JSON.parse(message.body)),
                takeUntil(this.disconnect$)
            );
    }

    printAlert(data: ToolTipData) {
        if (data.type === 'WARNING') {
            this.alertService.warning(`error.server.${data.userMessage}`);
        }
    }
}
