import { createActionGroup, createFeature, createReducer, on, props } from '@ngrx/store';
import { RemoveFromGroupPayload } from 'app/chat/chat.model';

export const chatGroupActions = createActionGroup({
    source: 'Chat Group',
    events: {
        openRemoveUserDialog: props<RemoveFromGroupPayload>(),
        removeUser: props<RemoveFromGroupPayload>(),
        userRemovedSuccess: props<RemoveFromGroupPayload>()
    }
});

export const chatMessageActions = createActionGroup({
    source: 'Chat Message',
    events: { messagesLoadedSuccess: props<{ resetScroll: boolean; scrollPosition: 'bottom' | 'keep' }>() }
});

const reducer = createReducer(
    { isLoading: false },
    on(chatGroupActions.removeUser, s => ({ ...s, isLoading: true })),
    on(chatGroupActions.userRemovedSuccess, s => ({ ...s, isLoading: false }))
);

export const chatFeature = createFeature({ name: 'chat', reducer });
