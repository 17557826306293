import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    private language = new BehaviorSubject('');
    private laguage$ = this.language.asObservable();
    private isHerbrew_ = new BehaviorSubject(false);
    private isHerbrew$ = this.isHerbrew_.asObservable();
    setLanguage(language: string) {
        this.language.next(language);
    }
    getLanguage() {
        return this.laguage$;
    }
    isHebrew() {
        return this.isHerbrew$;
    }
    setIsHebrew(isHerbrew: boolean) {
        this.isHerbrew_.next(isHerbrew);
    }
    getIsHebrew() {
        return this.isHerbrew_.getValue();
    }
}
