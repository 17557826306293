import { ApplicationConfig, ENVIRONMENT_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, withHashLocation, withRouterConfig } from '@angular/router';
import { appRoutes } from 'app/app.routes';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { authExpiredInterceptor, authInterceptor } from 'app/blocks/interceptor/auth.interceptor';
import { GlobalErrorHandler } from 'app/blocks/handler/global-error.handler';
import { attachGoogleAnalytics, sendUrlToGoogleAnalytics } from 'ga';
import { registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AngularSvgIconModule } from 'angular-svg-icon';
import srLatn from '@angular/common/locales/sr-Latn';
import ro from '@angular/common/locales/ro';
import de from '@angular/common/locales/de';
import hr from '@angular/common/locales/hr';
import no from '@angular/common/locales/no';
import hu from '@angular/common/locales/hu';
import sk from '@angular/common/locales/sk';
import he from '@angular/common/locales/he';
import cs from '@angular/common/locales/cs';
import { provideState, provideStore } from '@ngrx/store';
import { chatFeature } from 'app/chat/chat.state';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { sharedFeature } from 'app/store/shared.state';
import { provideEffects } from '@ngrx/effects';
import * as chatUnseenEffects from 'app/store/chat-unseen.effects';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: 'reload' }), withHashLocation()),
        provideHttpClient(withInterceptors([authInterceptor, authExpiredInterceptor])),
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue() {
                attachGoogleAnalytics();
                sendUrlToGoogleAnalytics();
                registerLocaleData(srLatn);
                registerLocaleData(ro);
                registerLocaleData(de);
                registerLocaleData(hr);
                registerLocaleData(no);
                registerLocaleData(he);
                registerLocaleData(hu);
                registerLocaleData(sk);
                registerLocaleData(cs);
            },
            multi: true
        },
        provideAnimations(),
        provideStore({ router: routerReducer }),
        provideRouterStore(),
        provideState(sharedFeature),
        provideState(chatFeature),
        provideEffects(chatUnseenEffects),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'content/i18n/', `.json`),
                    deps: [HttpClient]
                }
            }),
            NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
            AngularSvgIconModule.forRoot()
        )
    ]
};
