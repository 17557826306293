import { Injectable, inject } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class QuillLoaderService {
    readonly isQuillLoaded$ = this.lazyLoadQuill$();
    private readonly document = inject(DOCUMENT);

    private lazyLoadQuill$(): Observable<boolean> {
        return combineLatest([this.loadScript(), this.loadStyle('quill.core'), this.loadStyle('quill.snow')]).pipe(
            map(([script, cssCore, cssTheme]) => script && cssCore && cssTheme),
            shareReplay(1)
        );
    }

    private loadScript() {
        return new Observable<boolean>(obs => {
            const script = this.document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'quill.js';
            script.onload = () => {
                obs.next(true);
                obs.complete();
            };
            script.onerror = () => {
                obs.next(false);
                obs.complete();
            };
            this.document.body.appendChild(script);
        });
    }

    private loadStyle(path: string) {
        return new Observable<boolean>(obs => {
            const style = this.document.createElement('link');
            style.href = `${path}.css`;
            style.rel = 'stylesheet';
            style.onload = () => {
                obs.next(true);
                obs.complete();
            };
            style.onerror = () => {
                obs.next(false);
                obs.complete();
            };
            this.document.head.appendChild(style);
        });
    }
}
